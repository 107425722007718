<template>
  <div>
    <div class="p-banner">
      <img alt="" class="p-banner-bg" src="../../assets/img/product/banner3.png">
      <div class="container" v-animate-on-intersect>
        <div class="p-banner-title">{{ menuType == 1 ? '升华结晶技术' : menuType == 2 ? '升华结晶专用工艺设备' : '升华结晶成套设备' }}</div>
        <div class="p-banner-sub-title">{{ menuType == 1 ? '升华结晶技术' : menuType == 2 ? '升华结晶专用工艺设备' : '升华结晶成套设备' }}</div>
      </div>
    </div>
    <div class="page-menu d-flex-center">
      <div :class="menuType == 1 ? 'on' : ''" @click="menuType = 1">技术介绍</div>
      <div :class="menuType == 2 ? 'on' : ''" @click="menuType = 2">核心设备</div>
      <div :class="menuType == 3 ? 'on' : ''" @click="menuType = 3">成套装置</div>
    </div>
    <div v-if="menuType == 1" class="container">
      <div class="product-item-title">技术原理</div>
      <div class="page-text">升华结晶是指固态物质不经过液态直接转变为气态及气态物质不经过液态直接变为固态的过程，其包含固体升华和气体凝华两个过程。当待分离固态物系中各组分在气相中分压差存在显著差别，且各组分对热相对稳定，加热待分离固态物系，易升华组分变为气态从固态中挥发出来，冷却挥发出来的气体，使其凝华，即可得到易升华组分的提纯晶体。</div>
      <el-row class="flex-warp mb-40" justify="space-between" type="flex" :gutter="36">
        <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
          <div class="img-item">
            <div class="preview-image"><img class="page-img" src="../../assets/img/product/shjj1-1.png" v-image-preview alt=""></div>
          </div>
        </el-col>
        <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
          <div class="img-item">
            <div class="preview-image"><img class="page-img" src="../../assets/img/product/shjj1-2.png" v-image-preview alt=""></div>
          </div>
        </el-col>
        <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
          <div class="img-item">
            <div class="preview-image"><img class="page-img" src="../../assets/img/product/shjj1-3.png" v-image-preview alt=""></div>
          </div>
        </el-col>
        <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
          <div class="img-item">
            <div class="preview-image"><img class="page-img" src="../../assets/img/product/shjj1-4.png" v-image-preview alt=""></div>
          </div>
        </el-col>
      </el-row>
      <div class="product-item-title">典型应用</div>
      <el-row class="flex-warp mb-40 radius-10" v-animate-on-intersect justify="space-between" type="flex">
        <el-col :lg="13" :md="13" :sm="13" :xl="13" :xs="24">
          <div class="preview-image"><img class="box-img" style="height: 100%" src="../../assets/img/product/shjj1-5.png" v-image-preview ></div>
        </el-col>
        <el-col :lg="11" :md="11" :sm="11" :xl="11" :xs="24">
          <div class="dot-box">
            <div class="dot-list"><div class="dot"></div>从含碘废液中回收单质碘</div>
            <div class="dot-list"><div class="dot"></div>将粗硫升华结晶制备用于轮胎、胶管和胶板等升华硫</div>
            <div class="dot-list"><div class="dot"></div>将粗萘经过升华提纯得到98.5%以上纯度的精萘</div>
            <div class="dot-list"><div class="dot"></div>升华提纯粗樟脑至99.5%以上纯度</div>
            <div class="dot-list"><div class="dot"></div>精制提纯金属卤化物三氯化铁（FeCl3）、三氯化铝（AlCl3）、茂金属等产品</div>
            <div class="dot-list"><div class="dot"></div>从三聚氯氰、苯酐合成气体中连续凝华结晶分离产品</div>
            <div class="dot-list"><div class="dot"></div>精制提纯有机光电材料BCP、Alq3、Bpy-OXD、B5T、BP2T等产品</div>
            <div class="dot-list"><div class="dot"></div>分离回收或精制提纯水杨酸、对苯醌、苯甲酸、对苯二甲酸等产品</div>
          </div>
        </el-col>
      </el-row>
      <div class="product-item-title">技术特点</div>
      <div class="img-list mb-40" v-animate-on-intersect>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt="">不使用化学溶剂，不产生废液，符合绿色化工的要求</div>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt="">利用固体物质的升华特性进行分离纯化可得到高纯物质</div>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt="">可获得高质量可解析单晶</div>
      </div>
    </div>
    <div v-else-if="menuType == 2" class="container">
      <div class="product-item-title">概述</div>
      <div class="grey-box page-text mb-40" v-animate-on-intersect>升华结晶主要有三种工艺，分别为常压升华、真空升华和载气升华。能常压升华的物质不多，大部分物质只能在真空或载气条件下升华，真空升华最为节能，因此，我们开发了适宜真空连续升华的升华器和凝华器，可实现真空条件下的连续升华提纯。</div>
      <div class="product-item-title">升华器</div>
      <el-row class="flex-warp mb-40 radius-10 box-shadow" v-animate-on-intersect justify="space-between" type="flex">
        <el-col :lg="10" :md="10" :sm="10" :xl="10" :xs="24">
          <div class="preview-image"><img class="box-img" style="height: 100%" src="../../assets/img/product/shjj3-1.jpg" v-image-preview ></div>
        </el-col>
        <el-col :lg="14" :md="14" :sm="14" :xl="14" :xs="24">
          <div class="dot-box">
            <div class="dot-list f-bold">性能特点</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">可连续地、全封闭地生产</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">物料停留时间短</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">最终残渣中产品残留少</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">卓越的混合效率</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">加热表面可自清洁</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">能源消耗低</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">通过可更换的转子元件实现其灵活性</div>
          </div>
        </el-col>
      </el-row>
      <div class="product-item-title">凝华器</div>
      <el-row class="flex-warp mb-40 radius-10 box-shadow" v-animate-on-intersect justify="space-between" type="flex">
        <el-col :lg="10" :md="10" :sm="10" :xl="10" :xs="24">
          <div class="preview-image"><img class="box-img" style="height: 100%" src="../../assets/img/product/shjj3-2.jpg" v-image-preview ></div>
        </el-col>
        <el-col :lg="14" :md="14" :sm="14" :xl="14" :xs="24">
          <div class="dot-box">
            <div class="dot-list f-bold">性能特点</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">连续凝华、连续采出</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">可在-30～250℃,10～1000Pa操作条件下长期稳定运行</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">自动化程度高，一键启停</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">产品晶型可控(片状、粉状)</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">定向凝华，无结晶堵塞风险</div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div v-else-if="menuType == 3" class="container">
      <div class="product-item-title">概述</div>
      <div class="grey-box page-text mb-40" v-animate-on-intersect>升华结晶作为一种无溶剂结晶方法，在高纯产品的生产、物质新晶型筛选、高质量单晶制备等方面具有极大优势。升华结晶包含了升华和凝华两个过程，将升华器和凝华器进行组合，配套辅助设备、电气仪表和管道阀门，从而得到满足质量、收率、能耗等方面广泛要求的成套设备。</div>
      <div class="product-item-title">成套设备组成</div>
      <div class="page-text">升华结晶成套设备由升华器、凝华器、进料系统、出料系统、公用工程、氮气保护系统、管道阀门、电气仪表、控制系统、梯子平台、保温伴热等二级子单元组成，根据规模大小分可为撬装设备或现场安装系统。撬装设备将在生产车间完成组装和测试，然后分拆运输到客户现场进行二次组装并调试；现场安装系统将在客户现场完成组装和调试。</div>
      <el-row class="flex-warp mb-40" justify="space-between" type="flex" :gutter="50">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="img-item">
            <div class="img-item-title">升华结晶成套撬装设备</div>
            <div class="preview-image"><img class="page-img" src="../../assets/img/product/shjj4-1.png" alt="" v-image-preview ></div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="img-item">
            <div class="img-item-title"></div>
            <div class="preview-image"><img class="page-img" src="../../assets/img/product/shjj4-2.png" alt="" v-image-preview ></div>
          </div>
        </el-col>
      </el-row>
      <div class="product-item-title">成套设备的优点</div>
      <div class="img-list mb-40" v-animate-on-intersect>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt="">无论是合成物料的凝华结晶分离，还是固体物料的升华结晶提纯，匹配的辅助系统是装置长期稳定运行的关键，成套设备可以让你一步到位获取工艺性能保证的生产装置</div>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt="">撬装设备或现场安装系统服务将最大限度地的节省您投入项目的人力资源和精力，您可将其相关资源用在最能产生效益的地方</div>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt="">最大程度避免各供应商单⼀供货模式造成的设计与硬件匹配连贯性不⾜、多界⾯交接复杂、交付不确定、项⽬建设⼯期延长等问题</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // name: "升华结晶",
  data(){
    return{
      menuType:1
    }
  }
}
</script>

<style>
@import "../../assets/css/product.css";
</style>
